import styled from 'styled-components';

export const Container = styled.div`
  height: 80px;
  min-height: 80px;
  max-height: 80px;

  padding: 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  left: 0
`;
