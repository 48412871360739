import React from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Button, Typography } from '@material-ui/core';

// COMPONENTS
import Header from '../Header';

// UI
import { Container } from './styles';
import { Scrollable } from '../../UI';

function MPG() {
  const history = useHistory()

  const handleNewBroadcast = () => {
    history.push("/mpg/addBroadcast")
  }

  return <Container >
    <Header />

    <Box width="100%" height="calc(100% - 100px)" display="flex" flexDirection="column" justifyContent="flex-start" alignItems="center" padding="40px 20px 20px">

      <Typography variant="h3">MetaProducer Go</Typography>

      <Scrollable scrollbar="none" display="flex" flexDirection="column" alignItems="center" width="100%" height="auto" maxHeight="80%" margin="70px 0">

        <Typography variant="h6">No broadcasts..</Typography>
      </Scrollable>

      <Button onClick={handleNewBroadcast} size="small" variant="contained" color="primary">New Broadcast</Button>

    </Box>
  </Container>
}

export default MPG