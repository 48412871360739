import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

// TOASTFY
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// API
import { getMemberByGroupId } from '../../connections/groups'

// ICONS
import SettingsIcon from '@material-ui/icons/Settings';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import PersonAddDisabledIcon from '@material-ui/icons/PersonAddDisabled';

// STYLED
import { GroupSelect, ScrollBox } from './styles'
import { InvisibleBtn } from '../../UI';

// UI
import Modal from '../../UI/Modal';
import { TextField, Box, MenuItem, Typography, Select, Button } from '@material-ui/core';

function GroupManager() {

  const { user } = useSelector(redux => redux.authState)
  const { groups, defaultGroup } = user

  const [selectedGroup, setSelectedGroup] = useState(defaultGroup || groups ? groups[0]?.id : '')
  const [modal, setModal] = useState(false)

  const [editGroup, setEditGroup] = useState("")
  const [membersHTML, setMembersHTML] = useState(<Typography>Select a group to manage.</Typography>)

  const [newEmail, setNewEmail] = useState("")

  const getMembers = async () => {
    if (!editGroup) return setMembersHTML(<Typography>Select a group to manage.</Typography>)

    let members = await getMemberByGroupId(editGroup)
    console.log("MEMBERS", members)

    if (!members || !members.length) {
      return setMembersHTML(<Typography>Select a group to manage.</Typography>)
    }

    let arr = []

    members.forEach(member => {
      arr.push(
        <Box display="flex" width="100%" margin="8px 0 24px">
          <Box display="flex" flexDirection="column">
            <Typography variant="body" color="textPrimary">{member.firstName + " " + member.lastName}</Typography>
            <Typography variant="caption" color="textSecondary">{member.status}</Typography>
          </Box>

          <Box margin="0 0 0 auto">
            <InvisibleBtn onClick={() => handleRemoveMember(member)} disabled={member.status === 'Owner'} title={`Click to remove ${member.firstName + " " + member.lastName}`}>
              <PersonAddDisabledIcon color={member.status === 'Owner' ? "action" : "primary"} />
            </InvisibleBtn>
          </Box>
        </Box >

      )
    })

    setMembersHTML(arr)
  }

  useEffect(() => {
    getMembers()
  }, [editGroup])

  // HANDLERS
  const handleAddMember = () => {
    if (!editGroup) {
      return toast.error("Please select a Group to add the member")
    }

    if (!newEmail || newEmail.length < 7) {
      return toast.error("Please add a valid Guest Email")
    }

    alert("create new member func and integrate here")
    toast.success("Member invited with success!")
    setNewEmail("")
  }

  const handleRemoveMember = (member) => {
    if (!member || !member.status || member.status === "Owner") return

    alert("Remove user integration with backend here")
  }

  const handleClose = () => {
    setModal(false)
    setNewEmail("")
    setEditGroup("")
    setMembersHTML(<Typography>Select a group to manage.</Typography>)
  }

  return <Box padding="0 0 0 12px" height="80px" width="auto" position="absolute" left="0" display="flex" justifyContent="flex-start" alignItems="center">
    <Box height="80px" width="auto" display="flex" flexDirection="column" justifyContent="center" alignItems="flex-start">

      <Typography variant="h6" color="textSecondary">{groups.filter(g => g.id === selectedGroup)[0].name || ""}</Typography>

      <Box display="flex" justifyContent="center" alignItems="center">
        <Box margin="0 -78px 0 0">
          <Typography variant="caption" color="textSecondary">Switch group</Typography>
        </Box>

        <GroupSelect
          variant="outlined"
          value={''}
          onChange={e => setSelectedGroup(e.target.value)}
        >
          {groups.map(g => {
            return <MenuItem value={g.id}>{g.name}</MenuItem>
          })}
        </GroupSelect>

        <InvisibleBtn onClick={() => setModal(true)} title="Manage your groups" margin="0 0 0 12px">
          <SettingsIcon color="action" />
        </InvisibleBtn>
      </Box>
    </Box>

    <ToastContainer />

    <Modal overflow="hidden" height="700px" display="flex" justifyContent="top" isOpen={modal} close={() => setModal(false)} >
      <Box width="100%" display="flex" justifyContent="flex-end" alignItems="center" margin="0">
        <InvisibleBtn onClick={handleClose}>
          <Typography variant="h6" color="textPrimary">X</Typography>
        </InvisibleBtn>
      </Box>

      <Box margin="-20px 0 auto">
        <Typography variant="h3">Manage Group</Typography>
      </Box>

      <Box width="100%" height="89%" display="flex" flexDirection="column" justifyContent="center">
        <Box margin="0 0 8px">
          <Typography variant="h6" color="textSecondary">Group Name:</Typography>
        </Box>

        <Select
          variant="outlined"
          labelId="demo-simple-select-label"
          value={editGroup}
          onChange={e => setEditGroup(e.target.value)}
        >
          {groups.map(g => {
            return <MenuItem value={g.id}>{g.name}</MenuItem>
          })}
        </Select>

        <ScrollBox>
          <Box margin="0 0 8px">
            <Typography variant="h6" color="textSecondary">Members:</Typography>

            {membersHTML}
          </Box>
        </ScrollBox>

        <Box width="100%" margin="0 0 30px">
          <TextField value={newEmail} onChange={e => setNewEmail(e.target.value)} fullWidth variant="outlined" label="Guest Email" />

          <Box display="flex" justifyContent="flex-end" alignItems="center" margin="8px 0 0 auto">
            <InvisibleBtn onClick={handleAddMember}>
              <PersonAddIcon color="primary" />
              <Box margin="4px 0 0 8px">
                <Typography variant="caption" color="primary">Invite New Member</Typography>
              </Box>
            </InvisibleBtn>
          </Box>
        </Box>

        <Button onClick={handleClose} fullWidth variant="contained" color="primary">Save</Button>

      </Box>
    </Modal>
  </Box >


}

export default GroupManager;