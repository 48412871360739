import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

// API
import { register, confirmUser, login } from '../../connections/auth'
import { getTerms } from '../../connections/terms'

// TOASTFY
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// IMAGES
import loader from '../../assets/loader.svg'

// UI
import Modal from '../../UI/Modal'

// STYLES
import { Container, Input, Loader, ConfirmBtn, TextArea } from './styles';
import { Box, Button, Checkbox, Typography, Link } from '@material-ui/core';
import { Hoverable } from '../../UI'

// ACTIONS
import { authenticate } from '../../actions/auth';


function Signup() {

  const taRef = useRef()

  const dispatch = useDispatch()
  const history = useHistory()

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [cpassword, setCPassword] = useState('')

  const [termsObj, setTermsObj] = useState({})
  const [terms, setTerms] = useState(false)
  const [termsModal, setTermsModal] = useState(false)
  const [readTerms, setReadTerms] = useState(false)

  const [isLoading, setIsLoading] = useState(false)
  const [confirmEmailModal, setConfirmEmailModal] = useState(false)
  const [confirmCode, setConfirmCode] = useState('')

  const verify = () => {
    if (!terms) {
      return toast.error("You need to accept our terms to procced")
    }

    if (password !== cpassword) {
      return toast.error("Password didn't match")
    }

    handleSignup()
  }

  // HANDLERS
  const handleSignup = async () => {
    if (isLoading) return

    setIsLoading(true)

    try {

      let newUser = {
        email_address: email,
        first_name: firstName,
        last_name: lastName,
        password,
        agreement_id: termsObj.id
      }

      let response = await register(newUser)

      console.log("signup", response)
      setConfirmEmailModal(true)
    } catch (e) {
      toast.error(e.response.data.message);

    }

    setIsLoading(false)
  }

  const handleConfirmation = async () => {
    if (isLoading) return

    setIsLoading(true)

    try {
      await confirmUser({ email_address: email, verificationCode: confirmCode });
      let response = await login({ email_address: email, password });

      let userJWT = {
        accessJWT: response.accessToken.jwtToken,
        idJWT: response.idToken.jwtToken,
        refreshJWT: response.refreshToken.token
      }

      let user = {
        _id: response.databaseUser.id,
        name: response.databaseUser.first_name + " " + response.databaseUser.last_name,
        email: response.databaseUser.email_address,
        portrait: false,
        applications: ["3f894h398hf89345h"],
        session: userJWT
      }

      dispatch(authenticate(user))

      history.push("/dashboard")

    } catch (e) {

      toast.error(e.message);

    }

    setIsLoading(false)

  }

  const handleAgreeTerms = () => {
    if (!readTerms) return

    setTerms(true)
    setTermsModal(false)
  }

  const handleRefuseTerms = () => {
    setTerms(false)
    setTermsModal(false)
  }

  const handleScroll = e => {
    let node = e.target;

    const bottom = node.scrollHeight - node.scrollTop === node.clientHeight;

    if (bottom) {
      setReadTerms(true);
    }
  }

  // UI
  const getButtonContent = () => {
    return isLoading ? <Loader src={loader} /> : 'Register'
  }

  const setTermsHTML = () => {
    if (termsObj && termsObj.agreement_copy) {
      taRef.current.innerHTML = termsObj.agreement_copy
      taRef.current.addEventListener("scroll", handleScroll)
    }
  }

  useEffect(() => {
    setTermsHTML()
  }, [termsObj])

  // API
  const fetchTerms = async () => {
    let res = await getTerms()

    setTermsObj(res)
  }

  useEffect(() => {
    fetchTerms()
  }, [])

  return <Container>
    <Typography variant="h3">Create an Account</Typography>

    <Input value={firstName} onChange={e => setFirstName(e.target.value)} label="First Name" variant="outlined" margin="100px 0 0" />

    <Input value={lastName} onChange={e => setLastName(e.target.value)} label="Last Name" variant="outlined" margin="28px 0 0" />

    <Input value={email} onChange={e => setEmail(e.target.value)} label="Email" variant="outlined" margin="28px 0 0" />

    <Input value={password} onChange={e => setPassword(e.target.value)} label="Password" variant="outlined" margin="28px 0 0" type="password" />

    <Input value={cpassword} onChange={e => setCPassword(e.target.value)} label="Confirm Password" variant="outlined" margin="28px 0 0" type="password" />

    <Box margin="10px 0 0" width="300px" display="flex" alignItems="center" justifyContent="center">
      <Checkbox
        disableRipple
        checked={terms}
        onChange={() => setTermsModal(true)}
        inputProps={{ 'aria-label': 'secondary checkbox' }}
        color="primary"
      />
      <Typography variant="body1">
        Agree to terms and conditions
      </Typography>
    </Box>

    <Box onClick={() => setTermsModal(true)} margin="6px 0 10px" width="300px" display="flex" alignItems="center" justifyContent="center">
      <Hoverable>
        <Link underline="always" variant="subtitle1" >
          View terms and conditions
        </Link >
      </Hoverable>
    </Box >

    <Box width="100px" margin="10px">
      <Button fullWidth onClick={verify} size="big" variant="contained" color="primary">{getButtonContent()}</Button>
    </Box>

    <ToastContainer />

    <Modal isOpen={confirmEmailModal} close={() => setConfirmEmailModal(false)} >
      <Box margin="auto 0 20px">
        <Typography variant="h3">Check your email!</Typography>
      </Box>

      <Typography variant="body1" color="textSecondary">
        Please provide the code in your email to confirm your account.
      </Typography>

      <Box display="flex" alignItems="center" margin="20px 0 0">
        <Input value={confirmCode} onChange={e => setConfirmCode(e.target.value)} label="Code" variant="outlined" margin="28px 0 0" />

        <Box width="100px" margin="0 -10px -26px 20px">
          <ConfirmBtn fullWidth onClick={handleConfirmation} size="big" variant="contained" color="primary">Confirm</ConfirmBtn>
        </Box>
      </Box>


      <Box display="flex" alignItems="center" margin="auto 0 0">
        <Typography variant="caption" color="textSecondary">
          Didn't receive the email?  Please check your spam filter or click here to resend.
        </Typography>
      </Box>

    </Modal>

    <Modal isOpen={termsModal} close={() => setTermsModal(true)} >
      <Box margin="20px 0 20px">
        <Typography variant="h3">Terms and Conditions</Typography>
      </Box>

      <Typography variant="body1" color="textSecondary">
        Please read all the way to the bottom to be able to agree with terms.
      </Typography>

      <TextArea ref={taRef} />

      <Box display="flex" justifyContent="center" width="100%" margin="auto 0 20px 0">
        <Box width="200px" margin="0 12px">
          <ConfirmBtn fullWidth onClick={handleAgreeTerms} size="big" variant="contained" disabled={!readTerms} color={"primary"}>Agree</ConfirmBtn>
        </Box>
        <Box width="200px" margin="0 12px">
          <ConfirmBtn fullWidth onClick={handleRefuseTerms} size="big" variant="contained" color="primary">Refuse</ConfirmBtn>
        </Box>
      </Box>

    </Modal>
  </Container >;
}

export default Signup;