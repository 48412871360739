import React from 'react';
import { useHistory } from 'react-router-dom';

// ICONS
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

// UI
import { Container } from './styles';
import { InvisibleBtn } from '../../../UI';
import { Box, Typography } from '@material-ui/core';

function MSPbreadcrumbs() {

  const history = useHistory()

  return <Container>
    <InvisibleBtn onClick={() => history.push("/dashboard")}>
      <Box margin="0 0 -4px">
        <Typography variant="body1" color="textPrimary">
          <ArrowBackIosIcon fontSize="inherit" color="action" />
        </Typography>
      </Box>

      <Typography variant="body1" color="textPrimary">
        LiveCGI
      </Typography>
    </InvisibleBtn>

    <Box borderBottom="1px solid #3f51b5" margin="0 16px">
      <InvisibleBtn onClick={() => history.push("/mpg")}>
        <Typography variant="body1" color="primary">
          MPG
        </Typography>
      </InvisibleBtn>
    </Box>

    <Box margin="0 16px">
      <InvisibleBtn onClick={() => history.push("/mpg/destination")}>
        <Typography variant="body1" color="textPrimary">
          Destinations
        </Typography>
      </InvisibleBtn>
    </Box>

    <Box margin="0 16px">
      <InvisibleBtn onClick={() => history.push("/account/subscriptions")}>
        <Typography variant="body1" color="textPrimary">
          Subscriptions
        </Typography>
      </InvisibleBtn>
    </Box>
  </Container>;
}

export default MSPbreadcrumbs;