import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import YouTube from 'react-youtube';

// IMAGES
import img from '../../assets/Studio.png'
import loader from '../../assets/loader.svg'

// UI
import { Container, Input, TextArea } from './styles';
import { Loader } from '../../UI';
import { Box, Button, Typography } from '@material-ui/core';

// COMPONENTS
import Header from '../Header'

// API
import { createLead } from '../../connections/users'
import { useSelector } from 'react-redux';

const CGIProducts = [
  {
    _id: "1",
    name: "MetaProducer Go",
    nameAlt: "mpg",
    description: "Fully customizable, sponsor ready, virtual production studios that run like a video game, can take in live feeds and be used for a wide array of content creation",
    image: img,
    video: 'A5z8MpxSLZc',
    alt: "LiveCGI - MetaProducer Go Application",
    largeDescription: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Et ligula ullamcorper malesuada proin. Non blandit massa enim nec dui nunc mattis enim ut. Eu consequat ac felis donec et. Suspendisse potenti nullam ac tortor vitae purus faucibus ornare. Amet purus gravida quis blandit turpis. Lobortis mattis aliquam faucibus purus in massa tempor nec. Pharetra convallis posuere morbi leo urna molestie at elementum eu. Molestie nunc non blandit massa enim. Tortor aliquam nulla facilisi cras fermentum odio eu. Volutpat blandit aliquam etiam erat velit scelerisque in. Sed viverra tellus in hac habitasse platea dictumst vestibulum."
  },
  {
    _id: "2",
    name: "Metaverse Cloud Producer",
    nameAlt: "mcp",
    description: "Fully customizable, sponsor ready, virtual production studios that run like a video game, can take in live feeds and be used for a wide array of content creation",
    image: img,
    video: 'A5z8MpxSLZc',
    alt: "LiveCGI - Metaverse Cloud Producer Application",
    largeDescription: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Et ligula ullamcorper malesuada proin. Non blandit massa enim nec dui nunc mattis enim ut. Eu consequat ac felis donec et. Suspendisse potenti nullam ac tortor vitae purus faucibus ornare. Amet purus gravida quis blandit turpis. Lobortis mattis aliquam faucibus purus in massa tempor nec. Pharetra convallis posuere morbi leo urna molestie at elementum eu. Molestie nunc non blandit massa enim. Tortor aliquam nulla facilisi cras fermentum odio eu. Volutpat blandit aliquam etiam erat velit scelerisque in. Sed viverra tellus in hac habitasse platea dictumst vestibulum."
  },
  {
    _id: "3",
    name: "Quinstream",
    nameAlt: "quinstream",
    description: "A one of a kind video player that enables in-content user interactivity and revenue enhancing commercial transactions based on user preferences",
    image: img,
    video: 'cckSEzH2PXI',
    alt: "LiveCGI - Quinstream Application",
    largeDescription: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Et ligula ullamcorper malesuada proin. Non blandit massa enim nec dui nunc mattis enim ut. Eu consequat ac felis donec et. Suspendisse potenti nullam ac tortor vitae purus faucibus ornare. Amet purus gravida quis blandit turpis. Lobortis mattis aliquam faucibus purus in massa tempor nec. Pharetra convallis posuere morbi leo urna molestie at elementum eu. Molestie nunc non blandit massa enim. Tortor aliquam nulla facilisi cras fermentum odio eu. Volutpat blandit aliquam etiam erat velit scelerisque in. Sed viverra tellus in hac habitasse platea dictumst vestibulum."
  }
]

function Lead() {

  const [product, setProduct] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isFormSent, setIsFormSent] = useState(false)

  const [phoneNumber, setPhoneNumber] = useState("")
  const [message, setMessage] = useState("")

  const { application } = useParams()

  const { user } = useSelector(s => s.authState)

  const defineProduct = () => {
    return setProduct(CGIProducts.filter(p => p.nameAlt === application)[0])
  }

  useEffect(() => {
    defineProduct()
  }, [application])

  const opts = {
    height: '470',
    width: '868',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
    },
  };

  const getForm = () => {
    if (isLoading) {
      return <Loader width="100px" margin="40px auto" src={loader} />
    }

    if (isFormSent) {
      return <Box width="400px" margin="40px auto">
        <Typography align="center" variant="h6">
          Your messega has been received by LiveCGI, we'll contact you soon.
        </Typography>
      </Box>
    }

    return <>
      <Input value={phoneNumber} onChange={e => setPhoneNumber(e.target.value)} id="outlined-basic" label="Phone number" variant="outlined" />

      <TextArea value={message} onChange={e => setMessage(e.target.value)} aria-label="minimum height" rowsMin={9} rowsMax={20} placeholder="Tell us about your project..." />

      <Button onClick={handleSubmit} size="small" variant="contained" color="primary">Submit</Button>
    </>
  }

  const handleSubmit = async () => {
    setIsLoading(true)

    try {
      let params = {
        user_id: user._id,
        product_id: product._id,
        phone_number: phoneNumber,
        message
      }

      await createLead(params)

      setIsFormSent(true)
    } catch (error) {
      alert(error)
    }

    return setIsLoading(false)
  }

  return <Container>
    <Header />

    <Box width="100%" height="100%" display="flex" flexDirection="column" justifyContent="flex-start" alignItems="center" padding="40px 20px 20px">

      <Typography variant="h3">{product?.name}</Typography>

      <Box width="100%" maxWidth="1400px" height="100%" display="flex" justifyContent="flex-start" alignItems="center" margin="20px 0 0">
        <Box width="62%" height="100%" display="flex" flexDirection="column" justifyContent="flex-start" alignItems="center">
          <YouTube videoId={product?.video} opts={opts} />

          <Box margin="12px 0 0">
            <Typography variant="body1" color="textPrimary" component="p">
              {product.largeDescription}
            </Typography>
          </Box>

        </Box>

        <Box width="38%" height="100%" display="flex" flexDirection="column" justifyContent="flex-start" alignItems="center">

          <Typography variant="h5" color="textPrimary" component="p">
            Interested in learning more?
          </Typography>

          {getForm()}
        </Box>
      </Box>
    </Box>
  </Container>;
}

export default Lead;