import React, { useState, useEffect, useRef } from 'react';

// UTILS
import webRtcPlayer from '../../utils/webRtcPlayer';

// STYLED
import { Loader, Text, Button, Title, BGContainer } from '../../UI'
import { FeedContainer, GameFeed, TalentFeed, Container } from './styles'

function Talent({ gameUrl }) {

  let uws
  let captureStream
  let webRtcPlayerObj

  const talentVideo = useRef()
  const gameVideo = useRef()

  const [showGameVideo, setShowGameVideo] = useState(false)
  const [alreadyHasStream, setAlreadyHasStream] = useState(false)

  useEffect(() => {
    navigator.mediaDevices.getUserMedia({ video: { width: 848, height: 480 }, audio: true })
      .then(function (stream) {
        talentVideo.current.srcObject = stream;
        talentVideo.current.play();
      })
      .catch(function (err) {
        console.log("An error occurred: " + err);
      });
  }, [])

  const handleJoinGame = () => {
    if (showGameVideo) return

    setShowGameVideo(true)

    // RUN GAME LOCAL
    uws = new WebSocket('ws://127.0.0.1/');

    // RUN GAME ON EC2
    // uws = new WebSocket(`ws://${gameUrl}:81`);

    uws.onmessage = function (message) {
      var parsedMessage = JSON.parse(message.data);
      console.info('Received message: ' + message.data);

      switch (parsedMessage.type) {
        case "config":
          handleStartCall(parsedMessage)
          break;
        case "answer":
          onWebRtcAnswer(parsedMessage)
        case "iceCandidate":
          onWebRtcIce(parsedMessage.candidate)
        default:
          console.log('Unrecognized message: ', message);
      }
    }
  }

  // UNREAL related side functions
  const handleStartCall = async (config) => {
    captureStream = await navigator.mediaDevices.getUserMedia({ video: { width: 848, height: 480 }, audio: true });
    console.log("antes")
    webRtcPlayerObj = new webRtcPlayer({ peerConnectionOptions: config.peerConnectionOptions }, onTrackCB);
    console.log("depois")
    webRtcPlayerObj.onWebRtcOffer = function (offer) {
      if (uws) {
        let offerStr = JSON.stringify(offer);
        console.log(`-> SS: offer:\n${offerStr}`);
        uws.send(offerStr);
      }
    };

    webRtcPlayerObj.onWebRtcCandidate = function (candidate) {
      if (uws) {
        console.log(`-> SS: iceCandidate\n${JSON.stringify(candidate, undefined, 4)}`);
        uws.send(JSON.stringify({ type: 'iceCandidate', candidate: candidate }));
      }
    };

    webRtcPlayerObj.onDataChannelConnected = function () {
      if (uws) {
        console.log('WebRTC connected, waiting for video');
      }
    };

    createWebRtcOffer();
  }

  function createWebRtcOffer() {
    if (webRtcPlayerObj) {
      console.log('Creating offer');
      console.log('Starting connection to server, please wait');
      webRtcPlayerObj.createOffer(captureStream);
    } else {
      console.log('WebRTC player not setup, cannot create offer');
      console.log('Unable to setup video');
    }
  }

  function onWebRtcIce(iceCandidate) {
    if (webRtcPlayerObj) {
      if (iceCandidate) {
        webRtcPlayerObj.handleCandidateFromServer(iceCandidate);
      }
    }
  }

  function onWebRtcAnswer(webRTCData) {
    webRtcPlayerObj.receiveAnswer(webRTCData);
  }

  function onTrackCB(stream) {
    if (!alreadyHasStream) {
      setAlreadyHasStream(true)

      if (gameVideo && gameVideo.current) {
        gameVideo.current.srcObject = stream
        gameVideo.current.play();
      }
    }
  }

  return <BGContainer>
    <Container>

      <FeedContainer>
        <TalentFeed ref={talentVideo} />

        <GameFeed show={showGameVideo} ref={gameVideo} />
      </FeedContainer>

      <Button bgColor={showGameVideo ? '#FF2233' : false} onClick={handleJoinGame} width="600px" margin="40px 0">
        {showGameVideo ? 'Exit Game' : 'Join Game'}
      </Button>

    </Container>
  </BGContainer>;
}

export default Talent;