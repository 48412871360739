import React, { useState, useEffect, useRef } from 'react';

// UTILS
import { convertSecondsToString } from '../utils/functions'


// STYLED
import { Box, ModalOverlay } from './index'


function Modal({ isOpen, close, children, height, width, overflow }) {

  return <ModalOverlay isOpen={isOpen}>
    <Box overflowY={overflow} shadow="0 0 20px rgba(0,0,0,0.8)" bgColor="#FDFDFD" width={width || "900px"} height={height || "600px"} padding="30px 40px 20px">
      {children}
    </Box>
  </ModalOverlay>

}

export default Modal;