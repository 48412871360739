import styled from 'styled-components';
import { TextareaAutosize, TextField } from '@material-ui/core'

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  max-height: 100vh;

  overflow: auto;

  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  background-color: #F5F5F5;
`

export const VideoContainer = styled.video`
  width: 100%;
  height: auto;
`

export const Input = styled(TextField)`
  && {
    width: 300px;

    margin: 24px 0 12px;
  }
`
export const TextArea = styled(TextareaAutosize)`
  && {
    width: 300px;

    margin: 12px 0;

    padding: 8px;

    border-radius: 4px;
    background-color: transparent;
    border-color: rgb(118, 118, 118, 0.5);
    resize: none;

    &:hover {
      border-color: rgb(118, 118, 118);
    }

    &:focus {
      padding: 7px;
      border-color: #3f51b5;
      border-width: 2px;
    }
  }
`