import React, { useEffect, useRef } from 'react';

// IMAGES
import webcamBG from '../../assets/webcamBG.png'

// STYLED
import { BG, VideoBG, VideoPalyer, Container } from './styles'

function Preview() {

  const video = useRef()

  useEffect(() => {
    navigator.mediaDevices.getUserMedia({ video: { width: 848, height: 480 }, audio: true })
      .then(function (stream) {
        video.current.srcObject = stream;
        video.current.play();
      })
      .catch(function (err) {
        console.log("An error occurred: " + err);
      });
  }, [])



  return <Container>
    <BG src={webcamBG} />
    <VideoBG>
      <VideoPalyer ref={video} />
    </VideoBG>
  </Container>;
}

export default Preview;