import styled from 'styled-components';
import { Button, TextField } from '@material-ui/core'

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  max-height: 100vh;

  overflow: auto;

  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: #F5F5F5;
`

export const Input = styled(TextField)`
  && {
    width: 300px;

    margin: ${p => p.margin};
  }
`

export const ConfirmBtn = styled(Button)`
  && {
    height: 56px;
  }
`

export const Loader = styled.img`
  height: 24px;
`

export const TextArea = styled.div`
  max-height: 50%;
  height: 50%;
  width: 100%;
  overflow-y: scroll;

  margin: auto 0;

  padding: 6px 10px;

  white-space: normal;
  resize: none;
`