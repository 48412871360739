import React, { useState } from 'react';
import { Box, Typography } from '@material-ui/core'
import { useSelector } from 'react-redux';

// STYLED
import { UserAvatar, UserName } from './styles'
import { InvisibleBtn } from '../../UI';
import { useHistory } from 'react-router-dom';

// COMPONENTS
import GroupManager from '../GroupManager';
import MPGbreadcrumbs from '../MPG/MPGbreadcrumbs';

function Header() {

  const history = useHistory()
  const { pathname } = history.location
  console.log(pathname)
  const { user } = useSelector(redux => redux.authState)
  const { groups } = user

  const getLeftComponent = () => {
    if (groups?.length && pathname === '/dashboard') {
      return <GroupManager />
    }

    if (pathname.includes('/mpg')) {
      return <MPGbreadcrumbs />
    }
  }

  const getTitle = () => {
    if (pathname === '/dashboard') {
      return 'Live CGI'
    }

    return ''
  }

  return <Box width="100%" minHeight="80px" maxHeight="80px" display="flex" justifyContent="center" alignItems="center">

    {getLeftComponent()}

    <Typography variant="h3">{getTitle()}</Typography>

    <Box height="80px" width="260px" position="absolute" right="0" display="flex" justifyContent="flex-start" alignItems="center">
      <InvisibleBtn onClick={() => history.push("/settings")}>
        <UserAvatar src={user?.image} />
      </InvisibleBtn>

      <Box padding="0 0 0 12px" width="100%" height="80px" display="flex" flexDirection="column" justifyContent="center" alignItems="flex-start">
        <UserName variant="overline">{user?.name}</UserName>
        <Typography variant="caption">{user?.email}</Typography>
      </Box>
    </Box>

  </Box>
}

export default Header;