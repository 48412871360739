import styled from 'styled-components';
import studioBG from '../assets/Studio.png'

export const Loader = styled.img`
  width: ${p => p.width || '200px'};
  height: ${p => p.height || 'auto'};

  margin: ${p => p.margin || '0 auto'};
`

export const Text = styled.p`
  font-size: 20px;
  font-weight: bold;
  
  margin: 0 auto 24px;
`

export const Button = styled.button`
  width: ${p => p.width || '100%'};
  margin: ${p => p.margin || null};

  border: none;
  border-radius: 6px;
  background-color: ${p => p.disabled ? '#D3D3D3BF' : p.bgColor ? p.bgColor + 'BF' : '#FFFFFFBF'};

  display: block;
  padding-top: 10px;
  padding-bottom: 10px;
  transition: all ease 250ms;

  color: ${p => p.disabled ? '#EAEAEA' : p.color ? p.color : 'black'};
  font-size: 20px;
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  text-decoration: none;
  text-decoration: none;
  text-align: center;

  &:visited {
    color: black;
  }

  &:hover {
    background-color: ${p => p.disabled ? '#D3D3D3BF' : p.bgColor ? p.bgColor : '#FFFFFF'};
    cursor: ${p => p.disabled ? 'not-allowed' : 'pointer'};
  }
    
`

export const Title = styled.h1`
  margin: 0 0 20px;

  text-align: center;
  font-size: 75px;
  color: white;
`

export const BGContainer = styled.div`
  min-height: 100vh;
  min-width: 100vw;

  display: flex;
  align-items: center;
  justify-content: center;

  background-image: ${p => p.bgimage ? `url(${p.bgimage})` : `url(${studioBG})`};
  background-color: #1d1d1d;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
`

export const Box = styled.div`
  width: ${p => p.width || 'auto'};
  height: ${p => p.height || 'auto'};
  margin: ${p => p.margin || 'auto'};
  overflow-y: ${p => p.overflowY || 'auto'};
  overflow-x: ${p => p.overflowX || 'hidden'};
  
  display: flex;
  flex-direction: ${p => p.flexDirection || 'column'};
  justify-content: ${p => p.justify || 'center'};
  align-items: ${p => p.align || 'center'};
  
  padding: ${p => p.padding || '20px'};

  background-color: ${p => p.bgColor || 'rgba(255, 255, 255, 0.75)'};
  box-shadow: ${p => p.shadow};
  border-radius: 6px;
`

export const Flex = styled.div`
  width: ${p => p.width || 'auto'};
  height: ${p => p.height || 'auto'};
  max-width: ${p => p.maxWidth || 'auto'};
  max-height: ${p => p.maxHeight || 'auto'};
  min-width: ${p => p.maxWidth || 'auto'};
  min-height: ${p => p.minHeight || 'auto'};
  margin: ${p => p.margin || '0'};
  overflow: ${p => p.overflow || 'auto'};
  
  display: flex;
  flex-direction: ${p => p.flexDirection || 'column'};
  justify-content: ${p => p.justify || 'center'};
  align-items: ${p => p.align || 'center'};
  
  padding: ${p => p.padding || '20px'};
  background-color: ${p => p.bgColor};
`

export const MenuTitle = styled.p`
  width: ${p => p.width || 'auto'};
  margin: ${p => p.margin || '0'};

  display: flex;
  justify-content: flex-start;

  color: #FFFFFF;
  font: Bold 24px/29px 'Segoe UI';
`

export const Scrollable = styled.div`
  width: ${p => p.width || 'auto'};
  height: ${p => p.height || 'auto'};
  min-width: ${p => p.minWidth || 'auto'};
  min-height: ${p => p.minHeight || 'auto'};
  max-width: ${p => p.maxWidth || 'auto'};
  max-height: ${p => p.maxHeight || 'auto'};
  overflow: ${p => p.overflow || 'auto'};

  display: ${p => p.display || 'auto'};
  flex-direction: ${p => p.flexDirection || 'auto'};
  justify-content: ${p => p.justifyContent || 'auto'};
  align-items: ${p => p.alignItems || 'auto'};

  margin: ${p => p.margin || '0'};

  &::-webkit-scrollbar {
    display: ${p => p.scrollbar || 'auto'};
  }
`

export const ModalOverlay = styled.div`
  width: 100vw;
  height: 100vh;
  z-index: 10;

  display: ${p => p.isOpen ? 'flex' : 'none'};
  justify-content: center;
  align-items: center;

  position: absolute;
  top: 0;
  left: 0;
  
  background-color: #333333DD;
`

export const Select = styled.select`
  width: 300px;
  height: 60px;
  margin: ${p => p.margin || '0'};

  padding: 0 0 0 10px;

  color: ${p => p.color ? p.color : 'black'};
  font-size: 20px;
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  text-decoration: none;
  text-decoration: none;
  text-align: center;
`

export const InvisibleBtn = styled.button`
  width: ${p => p.width || 'auto'};
  height: ${p => p.height || 'auto'};
  margin: ${p => p.margin || '0'};

  display: flex;
  justify-content: center;
  align-items: center;

  padding: 0;

  background-color: transparent;
  border: none;

  transition: all ease 0.3s;

  &:hover {
    cursor: ${p => p.disabled ? "not-allowed" : "pointer"};
  }

  &:active {
    transform: scale(0.9, 0.9);
  }
`

export const Hoverable = styled.div`
  width: auto;
  height: auto;
  margin: ${p => p.margin || '0'};  

  &:hover {
    cursor: ${p => p.cursor || "pointer"};
  }
`