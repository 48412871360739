import React, { useEffect } from 'react';

import { Container, StreamContainer } from './styles';

// THREEJS
import * as THREE from 'three';
import { TrackballControls } from 'three/examples/jsm/controls/TrackballControls.js';
import { CSS3DRenderer, CSS3DObject } from 'three/examples/jsm/renderers/CSS3DRenderer.js';

function TestStudio() {
  let camera, scene, renderer;
  let controls;
  let stream

  useEffect(() => {
    navigator.mediaDevices.getUserMedia({ video: { width: 300 }, audio: true })
      .then(function (str) {
        stream = str;

        init();
        animate();
      })
      .catch(function (err) {
        console.log("An error occurred: " + err);
      });
  }, [])

  const init = () => {

    var container = document.getElementById('container');

    camera = new THREE.PerspectiveCamera(50, window.innerWidth / window.innerHeight, 1, 50);
    camera.position.set(200, 350, 750);

    scene = new THREE.Scene();

    renderer = new CSS3DRenderer();
    renderer.setSize(600, 400);
    container.appendChild(renderer.domElement);

    var group = new THREE.Group();
    group.add(Element('TlLijkYQjlw', 0, 0, 240, 0));
    group.add(Element('RbtgTFGDkxA', 240, 0, 0, Math.PI / 2));
    group.add(Element('fuyZFMRtXGs', 0, 0, - 240, Math.PI));
    group.add(Element('uqj9j-qz4AEz', - 240, 0, 0, - Math.PI / 2, stream));
    scene.add(group);

    controls = new TrackballControls(camera, renderer.domElement);
    controls.rotateSpeed = 4;

    window.addEventListener('resize', onWindowResize, false);

    // Block iframe events when dragging camera

    var blocker = document.getElementById('blocker');
    blocker.style.display = 'none';

    document.addEventListener('mousedown', function () {

      blocker.style.display = '';

    });
    document.addEventListener('mouseup', function () {

      blocker.style.display = 'none';

    });

  }

  const onWindowResize = () => {

    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();
    renderer.setSize(window.innerWidth, window.innerHeight);

  }

  const animate = () => {

    requestAnimationFrame(animate);
    controls.update();
    renderer.render(scene, camera);

  }

  const Element = (id, x, y, z, ry, stream) => {
    var div = document.createElement('div');
    div.style.width = '480px';
    div.style.height = '360px';
    div.style.backgroundColor = '#000';

    if (stream) {
      var video = document.createElement('video');
      video.style.width = '480px';
      video.style.height = '360px';
      video.style.border = '0px';
      video.srcObject = stream;
      video.play()
      div.appendChild(video);
    } else {
      console.log("STREAAAMM", stream)
      var iframe = document.createElement('iframe');
      iframe.style.width = '480px';
      iframe.style.height = '360px';
      iframe.style.border = '0px';
      iframe.src = ['https://www.youtube.com/embed/', id, '?rel=0&autoplay=1&mute=1'].join('');
      div.appendChild(iframe);
    }

    var object = new CSS3DObject(div);
    object.position.set(x, y, z);
    object.rotation.y = ry;
    return object;
  };

  return <Container>
    <StreamContainer id="container" />
    <div id="blocker"></div>
  </Container>;
}

export default TestStudio;