import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  max-height: 100vh;

  overflow: hidden;

  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: #F5F5F5;
`

export const StreamContainer = styled.div`
  width: 600px;
  height: 400px;

  display: flex;
  justify-content: center;
  align-items: center;

  border: 2px solid navy;
`;
