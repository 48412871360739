import axios from 'axios'

import { store } from '../store'

export const createLead = async (params) => {
  const { authState } = store.getState()

  if (!authState || !authState.user) {
    throw new Error("Please login to send the form!")
  }

  let config = {
    headers: {
      'Authorization': 'Bearer ' + authState.user.session.idJWT
    }
  }

  let res = await axios.post(`${process.env.REACT_APP_NODE_URL}/users/createLead`, params, config)

  return res
}