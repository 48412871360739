import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

// TOASTFY
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// IMAGES
import loader from '../../assets/loader.svg'

// UI
import Modal from '../../UI/Modal'
import { InvisibleBtn } from '../../UI'

// STYLES
import { Container, Input, Loader, ConfirmBtn, UserAvatar } from './styles';
import { Box, Button, Typography } from '@material-ui/core';

// COMPONENTS
import Header from '../Header'


function Settings() {

  const dispatch = useDispatch()
  const history = useHistory()

  const { user } = useSelector(state => state.authState)

  const [email, setEmail] = useState(user.email)
  const [name, setName] = useState(user.name)
  const [isLoading, setIsLoading] = useState(false)
  const [confirmEmailModal, setConfirmEmailModal] = useState(false)
  const [confirmCode, setConfirmCode] = useState('')

  const handleSignup = async () => {
    if (isLoading) return

    setIsLoading(true)

    try {

      // const newUser = await Auth.signUp({
      //   username: email,
      // });

      setConfirmEmailModal(true)
    } catch (e) {

      toast.error(e.message);

    }

    setIsLoading(false)
  }

  const handleConfirmation = async () => {
    if (isLoading) return

    setIsLoading(true)

    try {
      // await Auth.confirmSignUp(email, confirmCode);

      // let user = {
      //   _id: response.username,
      //   name: "Guillermo Benitez",
      //   email: response.attributes.email,
      //   portrait: false,
      //   applications: ["3f894h398hf89345h"],
      //   session: response.signInUserSession
      // }

      // dispatch(authenticate(user))

      history.push("/dashboard")

    } catch (e) {

      toast.error(e.message);

    }

    setIsLoading(false)

  }

  const getButtonContent = () => {
    return isLoading ? <Loader src={loader} /> : 'Save'
  }

  return <Container>
    <Header />

    <Box margin="12px 0 0">
      <Typography variant="h4">Settings</Typography>
    </Box>

    <Box width="100%" height="100%" display="flex" flexDirection="column" justifyContent="center" alignItems="center" margin="-80px 0 0">
      <Box width="100%" height="auto" display="flex" justifyContent="center" alignItems="center">

        <InvisibleBtn>
          <Box display="flex" flexDirection="column">
            <Box zIndex="0" margin="0 0 -20px">
              <UserAvatar src={user?.image} />
            </Box>

            <Box zIndex="1" margin="0 0 0 -40px">
              <CloudUploadIcon fontSize="large" color="primary" />
            </Box>
          </Box>
        </InvisibleBtn>

        <Box width="auto" height="auto" display="flex" flexDirection="column" justifyContent="center" alignItems="flex-start" margin="0 0 0 30px">
          <Typography variant="h3">{user.name}</Typography>
          <Typography variant="caption" color="textSecondary">{user.email}</Typography>
          <Typography variant="caption" color="textSecondary">(email confirmed)</Typography>
        </Box>
      </Box>

      <Input value={name} onChange={e => setName(e.target.value)} label="Name" variant="outlined" margin="40px 0 0" />

      <Input value={email} onChange={e => setEmail(e.target.value)} label="Email" variant="outlined" margin="28px 0 0" />

      <Box width="100px" margin="40px 10px 10px">
        <Button fullWidth onClick={handleSignup} size="big" variant="contained" color="primary">{getButtonContent()}</Button>
      </Box>
    </Box>

    <ToastContainer />

    <Modal isOpen={confirmEmailModal} close={() => setConfirmEmailModal(false)} >
      <Box margin="auto 0 20px">
        <Typography variant="h3">Check your email!</Typography>
      </Box>

      <Typography variant="body1" color="textSecondary">
        Please provide the code in your email to confirm your account.
      </Typography>

      <Box display="flex" alignItems="center" margin="20px 0 0">
        <Input value={confirmCode} onChange={e => setConfirmCode(e.target.value)} label="Code" variant="outlined" margin="28px 0 0" />

        <Box width="100px" margin="0 -10px -26px 20px">
          <ConfirmBtn fullWidth onClick={handleConfirmation} size="big" variant="contained" color="primary">Confirm</ConfirmBtn>
        </Box>
      </Box>


      <Box display="flex" alignItems="center" margin="auto 0 0">
        <Typography variant="caption" color="textSecondary">
          Didn't receive the email?  Please check your spam filter or click here to resend.
        </Typography>
      </Box>

    </Modal>
  </Container>;
}

export default Settings;