import React, { useState, useEffect, useRef } from 'react';

// UTILS
import { convertSecondsToString } from '../../utils/functions'


// STYLED
import { Box, Flex, MenuTitle, Button, ModalOverlay, Select } from '../../UI'
import { Subtitle } from './styles'


function AddTrackModal({ monitors, setMonitors, midias, selectedMenu, addTrackModal, setAddTrackModal }) {

  const [selectedTrack, setSelectedTrack] = useState(false)
  const [selectedTrackID, setSelectedTrackID] = useState(false)

  // HANDLERS
  const handleCloseModal = () => {
    setSelectedTrackID(false)
    setSelectedTrack(false)
    setAddTrackModal(false)
  }

  const handleSelectTrack = (id) => {
    setSelectedTrackID(id)
    setSelectedTrack(midias.filter(midia => midia.id === id)[0])
  }

  const handleAddTrack = () => {
    if (!selectedTrack) return

    let position = false

    let newMonitor = {
      ...monitors.filter((monitor, index) => {

        if (monitor.name === selectedMenu) {
          position = index
          return true
        }

        return false
      })[0]
    }

    selectedTrack.start = 0
    selectedTrack.end = 0

    newMonitor.tracks = [...newMonitor.tracks, selectedTrack]

    let newMonitors = [...monitors]
    newMonitors[position] = newMonitor

    setMonitors(newMonitors)
    setSelectedTrackID(false)
    setSelectedTrack(false)
    setAddTrackModal(false)
  }

  return <ModalOverlay isOpen={addTrackModal}>
    <Box shadow="0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)" bgColor="#424242" width="380px" height="50%" justify="flex-start" padding="30px 40px 20px">
      <MenuTitle>Add a Track</MenuTitle>
      <Subtitle margin="30px auto 0 0">Select:</Subtitle>
      <Select margin="10px auto 0 0" onChange={e => handleSelectTrack(e.target.value)} value={selectedTrackID} placeholder="Please select">
        <option value="">Select a track</option>
        {midias?.map(track => {
          return <option value={track.id}>{track.name}</option>
        })}
      </Select>

      <Flex width="100%" align="flex-start" margin="40px 0" padding="0">
        <Subtitle>Track Type: {selectedTrack && selectedTrack.type.toUpperCase()}</Subtitle>
        <Subtitle>Track Duration: {selectedTrack && convertSecondsToString(selectedTrack.duration)}</Subtitle>
      </Flex>

      <Flex flexDirection="row" margin="auto 0 0">
        <Button onClick={handleAddTrack} disabled={!selectedTrack} bgColor="#22EE22" width="140px" margin="0 20px 0 0">
          Add Track
        </Button>

        <Button onClick={handleCloseModal} bgColor="#EE2222" color="#202020" width="140px" margin="0">
          Cancel
        </Button>
      </Flex>
    </Box>
  </ModalOverlay>

}

export default AddTrackModal;