import React, { useState } from 'react';

// UTILS
import { convertSecondsToString, truncateText } from '../../utils/functions'

// COMPONENTS
import TimeModal from './TimeModal'

// CONSTANTS
import ColorPalette from '../../constants/ColorPalette'

// STYLED
import { Flex } from '../../UI'
import { GrayBar, ShadowFlex, TrackTitle, TrackType, TrackTimer, DeleteTrack, SetTime } from './styles'


function Track({ handleRemoveTrack, track, index, handleEditTrack, flowDuration }) {

  const [timeModal, setTimeModal] = useState(false)

  return <ShadowFlex>
    <GrayBar>
      <Flex width="40px" height="100%" bgColor={ColorPalette[index]} padding="0" />
      <TrackTitle>{truncateText(track?.name, 26)}</TrackTitle>
      <TrackType>{track?.type}</TrackType>
      <TrackTimer>{convertSecondsToString(track?.start)}</TrackTimer>
      <TrackTimer>{convertSecondsToString(track?.end)}</TrackTimer>
      <SetTime onClick={() => setTimeModal(true)}>
        Set Start/Finish
      </SetTime>
      <DeleteTrack onClick={() => handleRemoveTrack(index)}>Remove Track</DeleteTrack>
    </GrayBar>
    <TimeModal flowDuration={flowDuration} index={index} handleEditTrack={handleEditTrack} timeModal={timeModal} setTimeModal={setTimeModal} />
  </ShadowFlex>
}

export default Track;