import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

// COMPONENTS
import Header from '../../Header';

// UI
import { InvisibleBtn, Scrollable } from '../../../UI';
import { Container, Input, Video } from './styles';
import { Box, Button, Typography, Checkbox, MenuItem, FormControl, InputLabel } from '@material-ui/core';

function Setup() {

  const [title, setTitle] = useState("")

  const videoHTML = useRef()
  const history = useHistory()

  useEffect(() => {
    navigator.mediaDevices.getUserMedia({ video: { width: 300 }, audio: true })
      .then(function (stream) {
        videoHTML.current.srcObject = stream;
        videoHTML.current.play();
      })
      .catch(function (err) {
        console.log("An error occurred: " + err);
      });
  }, [])

  return <Container>
    <Header />

    <Box width="100%" height="calc(100% - 100px)" display="flex" flexDirection="column" justifyContent="flex-start" alignItems="center" padding="40px 20px 20px">

      <Typography variant="h3">MetaProducer Go</Typography>

      <Box margin="24px 0">
        <Typography variant="h5">Prepare to enter studio</Typography>
      </Box>

      <Box margin="12px 0 36px" display="flex" flexDirection="column" alignItems="center">
        <Typography variant="body1" color="textSecondary">Your Camera</Typography>

        <Video ref={videoHTML} />
      </Box>

      <Box margin="12px 0 24px">
        <Input value={title} onChange={e => setTitle(e.target.value)} label="Display Name" variant="outlined" />
      </Box>

      <Button onClick={() => history.push("/mpg/test")} size="large" variant="contained" color="primary">Enter Studio</Button>

    </Box>
  </Container >
}

export default Setup;