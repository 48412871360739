import styled from 'styled-components';
import { Select, Box } from '@material-ui/core'

export const GroupSelect = styled(Select)`
  && {
    height: 34px;
    width: 250px;
    overflow: hidden;
  }
`
export const ScrollBox = styled(Box)`
  && {
    width: 100%;
    min-height: 44%;
    height: 44%;
    max-height: 44%;
    overflow: auto;
    padding: 24px 0;

    &::-webkit-scrollbar {
      display: none;
    }
  }
`