import React, { useState } from 'react';

// UTILS
import { convertSecondsToString } from '../../utils/functions'

// COMPONENTS
import DurationModal from './DurationModal'
import BarGraphic from './BarGraphic'

// STYLED
import { MenuTitle, Scrollable } from '../../UI'
import { ChangeDuration } from './styles'


function All({ monitors, flowDuration, setFlowDuration }) {

  const [durationModal, setDurationModal] = useState(false)

  return <Scrollable width="100%">

    <MenuTitle margin="40px auto 0 20px">Duration of show: {convertSecondsToString(flowDuration)}</MenuTitle>
    <ChangeDuration onClick={() => setDurationModal(true)}>Change Duration</ChangeDuration>

    <MenuTitle margin="50px auto 0 20px">Flows:</MenuTitle>

    {monitors.map(monitor => {
      return <BarGraphic name={monitor.name} tracks={monitor.tracks} flowDuration={flowDuration} />
    })}

    <DurationModal flowDuration={flowDuration} durationModal={durationModal} setDurationModal={setDurationModal} setFlowDuration={setFlowDuration} />
  </Scrollable>
}

export default All;