import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  max-height: 100vh;

  overflow: hidden;

  box-sizing: border-box;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: black;
  position: absolute;
`

export const BG = styled.img`
  width: 1750px;
  height: 1030px;

  position: absolute;
`

export const VideoBG = styled.div`
  width: 848px;
  height: 480px;
  
  overflow: hidden;
  margin: 0 0 9px 85px;

  z-index: 10;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: black;
`;

export const VideoPalyer = styled.video`
  width: 848px;
  height: 480px;

  position: absolute;
`;
