// TYPES
import { LOGIN_SUCCESSFULL, LOGOUT, CHANGE_STATUS, UPDATE_USER } from '../constants/actionTypes'

const INITIAL_STATE = {
  user: {
    _id: "",
    name: "Guillermo Benitez",
    email: "",
    portrait: false,
    applications: ["3f894h398hf89345h"],
    session: {},
    groups: [],
    defaultGroup: ""
  },
  isLogged: false
};

const auth = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGIN_SUCCESSFULL:
      return { ...state, user: action.payload.user, isLogged: true };

    case LOGOUT:
      return INITIAL_STATE;

    default:
      return state;
  }
}

export default auth;