import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  max-height: 100vh;

  overflow: hidden;

  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
export const FeedContainer = styled.div`
  width: 100%;
  height: auto;

  overflow: hidden;

  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

export const BG = styled.img`
  width: 1750px;
  height: 1030px;

  position: absolute;
`

export const TalentFeed = styled.video`
  width: 600px;
  height: auto;
`;

export const GameFeed = styled.video`
  width: 600px;
  height: auto;

  display: ${p => p.show ? 'flex' : 'none'};
`;
