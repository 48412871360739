export const convertSecondsToString = (sec) => {
  let seconds = sec
  let minutes = 0
  let hours = 0

  if (!sec) return '00:00:00'

  if (sec < 60) return sec < 10 ? '00:00:0' + sec : '00:00:' + sec

  if (sec < 3600) {
    minutes = Math.floor(sec / 60)
    minutes = minutes < 10 ? ':0' + minutes : ':' + minutes

    seconds = sec % 60
    seconds = seconds < 10 ? ':0' + seconds : ':' + seconds

    return '00' + minutes + seconds
  }

  if (sec < 86400) {
    hours = Math.floor(sec / 3600)
    hours = hours < 10 ? '0' + hours : '' + hours

    minutes = Math.floor((sec % 3600) / 60)
    minutes = minutes < 10 ? ':0' + minutes : ':' + minutes

    seconds = sec % 60
    seconds = seconds < 10 ? ':0' + seconds : ':' + seconds

    return hours + minutes + seconds
  }

  return 'More than 1 day'
}

export const truncateText = (text, max = 30) => {
  if (!text) return

  if (text.length > max) {
    return text.substring(0, max) + '...'
  } else return text
}