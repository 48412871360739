import axios from 'axios'

export const login = async (params) => {
  let res = await axios.post(`${process.env.REACT_APP_NODE_URL}/auth/login`, params)

  return res.data
}

export const register = async (params) => {
  let res = await axios.post(`${process.env.REACT_APP_NODE_URL}/auth/register`, params)

  return res
}

export const confirmUser = async (params) => {
  let res = await axios.post(`${process.env.REACT_APP_NODE_URL}/auth/confirm`, params)

  return res
}