import React, { useEffect, useState } from 'react';

// COMPONENTS

// CONSTANTS
import ColorPalette from '../../constants/ColorPalette'

// STYLED
import { GrayBar, ShadowFlex, Track, ScreenThumb } from './styles'


function BarGraphic({ tracks, flowDuration, name }) {

  const renderTracks = () => {
    let arr = []

    tracks?.forEach((track, index) => {
      let trackDuration = track.end - track.start
      let trackPercentage = (trackDuration / flowDuration) * 100
      let trackRightMargin = (track.start / flowDuration) * 100

      arr.push(
        <Track color={ColorPalette[index]} width={trackPercentage + '%'} margin={"0 0 0 " + trackRightMargin + '%'} />
      )
    })

    return arr
  }

  useEffect(() => {
    renderTracks()
  }, [tracks])

  return <ShadowFlex>
    <ScreenThumb>
      {name}
    </ScreenThumb>
    <GrayBar padding="0">
      {renderTracks()}
    </GrayBar>
  </ShadowFlex>
}

export default BarGraphic;