import styled from 'styled-components';
import { Avatar, Typography, Card, CardMedia } from '@material-ui/core'

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  max-height: 100vh;

  overflow: auto;

  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  background-color: #F5F5F5;
`

export const UserAvatar = styled(Avatar)`
  && {
    width: 60px;
    height: 60px;
  }
`

export const UserName = styled(Typography)`
  && {
    line-height: 2;
    color: blue;

    border-bottom: 1px solid blue;
  }
`

export const CardContainer = styled(Card)`
  && {
    max-width: 350px;
    margin: 0 20px;
  }
`

export const CardImg = styled(CardMedia)`
  && {
    height: 140px;
  }
`