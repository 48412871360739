import React from 'react';
import { Box, Typography } from '@material-ui/core'
import { useSelector } from 'react-redux';

// IMAGES
import img from '../../assets/Studio.png'

// COMPONENTS
import ProductCard from './ProductCard'
import Header from '../Header'

// STYLED
import { Container } from './styles'

const CGIProducts = [
  {
    _id: "3f894h398hf89345h",
    name: "MetaProducer Go",
    nameAlt: "mpg",
    description: "Fully customizable, sponsor ready, virtual production studios that run like a video game, can take in live feeds and be used for a wide array of content creation",
    image: img,
    alt: "LiveCGI - MetaProducer Go Application",
    largeDescription: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Et ligula ullamcorper malesuada proin. Non blandit massa enim nec dui nunc mattis enim ut. Eu consequat ac felis donec et. Suspendisse potenti nullam ac tortor vitae purus faucibus ornare. Amet purus gravida quis blandit turpis. Lobortis mattis aliquam faucibus purus in massa tempor nec. Pharetra convallis posuere morbi leo urna molestie at elementum eu. Molestie nunc non blandit massa enim. Tortor aliquam nulla facilisi cras fermentum odio eu. Volutpat blandit aliquam etiam erat velit scelerisque in. Sed viverra tellus in hac habitasse platea dictumst vestibulum."
  },
  {
    _id: "4590gj40j9045jf09j",
    name: "Metaverse Cloud Producer",
    nameAlt: "mcp",
    description: "Fully customizable, sponsor ready, virtual production studios that run like a video game, can take in live feeds and be used for a wide array of content creation",
    image: img,
    alt: "LiveCGI - Metaverse Cloud Producer Application",
    largeDescription: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Et ligula ullamcorper malesuada proin. Non blandit massa enim nec dui nunc mattis enim ut. Eu consequat ac felis donec et. Suspendisse potenti nullam ac tortor vitae purus faucibus ornare. Amet purus gravida quis blandit turpis. Lobortis mattis aliquam faucibus purus in massa tempor nec. Pharetra convallis posuere morbi leo urna molestie at elementum eu. Molestie nunc non blandit massa enim. Tortor aliquam nulla facilisi cras fermentum odio eu. Volutpat blandit aliquam etiam erat velit scelerisque in. Sed viverra tellus in hac habitasse platea dictumst vestibulum."
  },
  {
    _id: "9340-28ldk5dc29x39",
    name: "Quinstream",
    nameAlt: "quinstream",
    description: "A one of a kind video player that enables in-content user interactivity and revenue enhancing commercial transactions based on user preferences",
    image: img,
    alt: "LiveCGI - Quinstream Application",
    largeDescription: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Et ligula ullamcorper malesuada proin. Non blandit massa enim nec dui nunc mattis enim ut. Eu consequat ac felis donec et. Suspendisse potenti nullam ac tortor vitae purus faucibus ornare. Amet purus gravida quis blandit turpis. Lobortis mattis aliquam faucibus purus in massa tempor nec. Pharetra convallis posuere morbi leo urna molestie at elementum eu. Molestie nunc non blandit massa enim. Tortor aliquam nulla facilisi cras fermentum odio eu. Volutpat blandit aliquam etiam erat velit scelerisque in. Sed viverra tellus in hac habitasse platea dictumst vestibulum."
  }
]

function Dashboard() {

  return <Container>
    <Header />

    <Box width="100%" height="100%" display="flex" justifyContent="center" alignItems="center">
      {CGIProducts.map(product => {
        return <ProductCard key={product.id} product={product} />
      })}
    </Box>
  </Container>
}

export default Dashboard;