import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

// COMPONENTS
import Header from '../../Header';

// UI
import { InvisibleBtn, Scrollable } from '../../../UI';
import { Container, Input, BroadcasrSelect } from './styles';
import { Box, Button, Typography, Checkbox, MenuItem, FormControl, InputLabel } from '@material-ui/core';

function AddBroadcast() {

  const history = useHistory()

  const [destinations, setDestinations] = useState([])
  const [title, setTitle] = useState("")
  const [category, setCategory] = useState("")

  const handleDestinations = (str) => {
    if (destinations.includes(str)) {

      let arr = destinations.filter(destination => destination !== str)

      return setDestinations(arr)
    }

    setDestinations(state => [...state, str])
  }

  return <Container>
    <Header />

    <Box width="100%" height="calc(100% - 100px)" display="flex" flexDirection="column" justifyContent="flex-start" alignItems="center" padding="40px 20px 20px">

      <Typography variant="h3">MetaProducer Go</Typography>

      <Box margin="24px 0 0">
        <Typography variant="h5">Add Broadcast</Typography>
      </Box>

      <Scrollable scrollbar="none" display="flex" flexDirection="column" alignItems="flex-start" width="190px" height="auto" maxHeight="50%" margin="30px 0 50px">

        <Typography variant="body1" color="textSecondary">Select destinations</Typography>

        <Box margin="10px 0 0 -10px" width="100%" display="flex" alignItems="center" justifyContent="flex-start">
          <Checkbox
            disableRipple
            checked={destinations.includes("twitch")}
            onChange={() => handleDestinations("twitch")}
            inputProps={{ 'aria-label': 'secondary checkbox' }}
            color="primary"
          />
          <Typography variant="body1">
            Twitch
          </Typography>
        </Box>

        <InvisibleBtn onClick={() => history.push("/mpg/destination")}>
          <Typography variant="h4" color="primary">
            +
          </Typography>
          <Box margin="0 0 0 12px" borderBottom="1px solid #3f51b5">
            <Typography variant="body1" color="primary">
              Add New Destination
            </Typography>
          </Box>
        </InvisibleBtn>

      </Scrollable>

      <Input value={title} onChange={e => setTitle(e.target.value)} label="Title" variant="outlined" />

      <Box margin="12px 0">
        <FormControl variant="outlined">
          <InputLabel id="demo-simple-select-outlined-label">Category</InputLabel>
          <BroadcasrSelect
            labelId="demo-simple-select-outlined-label"
            autoWidth
            value={category}
            onChange={e => setCategory(e.target.value)}
          >
            <MenuItem value={10}>Ten</MenuItem>
            <MenuItem value={20}>Twenty</MenuItem>
            <MenuItem value={30}>Thirty</MenuItem>
          </BroadcasrSelect>
        </FormControl>
      </Box>

      <Button onClick={() => history.push("/mpg/setup/1")} size="large" variant="contained" color="primary">New Broadcast</Button>

    </Box>
  </Container >
}

export default AddBroadcast;