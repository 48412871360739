import styled from 'styled-components';
import { Avatar, Typography } from '@material-ui/core'

export const UserAvatar = styled(Avatar)`
  && {
    width: 60px;
    height: 60px;
  }
`

export const UserName = styled(Typography)`
  && {
    line-height: 2;
    color: blue;

    border-bottom: 1px solid blue;
  }
`