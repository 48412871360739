import { useState } from 'react'

// PAGES
import Home from './components/home/Home'
import Login from './components/login/Login'
import Logout from './components/logout/Logout'
import Preview from './components/Preview'
import Talent from './components/Talent'
import Controls from './components/Controls'
import Dashboard from './components/Dashboard'
import Lead from './components/Lead'
import Portal from './components/Portal'
import Signup from './components/Signup'
import Settings from './components/Settings'

// MCP
import MPG from './components/MPG'
import AddBroadcast from './components/MPG/AddBroadcast'
import Setup from './components/MPG/Setup'
import TestStudio from './components/MPG/TestStudio'

import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

export default function App() {

  const [gameUrl, setGameUrl] = useState('')

  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Portal setGameUrl={setGameUrl} />
        </Route>
        {/* <Route exact path="/">
          <Home setGameUrl={setGameUrl} />
        </Route> */}

        <Route exact path="/talent">
          <Talent gameUrl={gameUrl} />
        </Route>

        <Route path="/login" component={Login} />
        <Route path="/preview" component={Preview} />
        <Route path="/controls" component={Controls} />
        <Route path="/dashboard" component={Dashboard} />
        <Route path="/lead/:application" component={Lead} />
        <Route path="/signup" component={Signup} />
        <Route path="/settings" component={Settings} />

        {/* MPG ROUTES */}
        <Route path="/mpg" exact component={MPG} />
        <Route path="/mpg/destination" component={Setup} />
        <Route path="/mpg/addBroadcast" component={AddBroadcast} />
        <Route path="/mpg/setup/:broadcastId" component={Setup} />
        <Route path="/mpg/test" component={TestStudio} />
      </Switch>
    </Router>
  );
}

