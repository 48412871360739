import React, { useState } from 'react';

// COMPONENTS
import AddTrackModal from './AddTrackModal'
import Track from './Track'
import BarGraphic from './BarGraphic'

// STYLED
import { MenuTitle, Scrollable, Button } from '../../UI'


function Monitor({ monitors, setMonitors, flowDuration, tracks, selectedMenu, midias, selectedMonitor }) {

  const [addTrackModal, setAddTrackModal] = useState(false)

  // HANDLERS
  const handleRemoveTrack = (index) => {

    let position = false

    let newMonitor = {
      ...monitors.filter((monitor, i) => {

        if (monitor.name === selectedMenu) {
          position = i
          return true
        }

        return false
      })[0]
    }

    newMonitor.tracks.splice(index, 1)

    let newMonitors = [...monitors]
    newMonitors[position] = newMonitor

    setMonitors(newMonitors)
  }

  const handleEditTrack = (index, start, end) => {

    let position = false

    let newMonitor = {
      ...monitors.filter((monitor, i) => {

        if (monitor.name === selectedMenu) {
          position = i
          return true
        }

        return false
      })[0]
    }

    newMonitor.tracks[index].start = start
    newMonitor.tracks[index].end = end

    let newMonitors = [...monitors]
    newMonitors[position] = newMonitor

    setMonitors(newMonitors)
  }

  return <Scrollable height="100%" width="100%">
    <MenuTitle margin="0 auto 0 20px">Tracks:</MenuTitle>

    {tracks?.map((track, index) => {
      return <Track flowDuration={flowDuration} handleEditTrack={handleEditTrack} track={track} index={index} handleRemoveTrack={handleRemoveTrack} />
    })}

    {tracks?.length < 6 ? <Button onClick={() => setAddTrackModal(true)} color="#FFFFFF" width="calc(100% - 40px)" margin="10px auto" bgColor="#424242">
      + Add Track
    </Button> : null}

    <MenuTitle margin="80px auto 0 20px">Final Flow:</MenuTitle>

    <BarGraphic name={selectedMonitor?.name} tracks={tracks} flowDuration={flowDuration} />

    <AddTrackModal monitors={monitors} setMonitors={setMonitors} midias={midias} selectedMenu={selectedMenu} addTrackModal={addTrackModal} setAddTrackModal={setAddTrackModal} />
  </Scrollable>

}

export default Monitor;