import React, { useState, useEffect, useRef } from 'react';

// TOASTFY
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// UTILS
import { convertSecondsToString } from '../../utils/functions'


// STYLED
import { Box, Flex, MenuTitle, Button, ModalOverlay } from '../../UI'
import { Subtitle, TimeButton, TimeText } from './styles'


function TimeModal({ handleEditTrack, timeModal, setTimeModal, index, flowDuration }) {

  const [initialSec, setInitialSec] = useState(0)
  const [initialMin, setInitialMin] = useState(0)
  const [initialHour, setInitialHour] = useState(0)

  const [finalSec, setFinalSec] = useState(0)
  const [finalMin, setFinalMin] = useState(0)
  const [finalHour, setFinalHour] = useState(0)

  // UI
  const timeToText = (time) => {
    return time < 10 ? '0' + time : '' + time
  }

  // HANDLERS
  const handleCloseModal = () => {
    setTimeModal(false)
  }

  const handleFinish = () => {
    let initialAmmount = (initialHour * 3600) + (initialMin * 60) + initialSec
    let finalAmmount = (finalHour * 3600) + (finalMin * 60) + finalSec

    if (initialAmmount >= finalAmmount) {
      return toast.error("The initial time should be before the final time!", {
        position: "top-right",
        autoClose: 5000,
      });
    }

    if (finalAmmount > flowDuration) {
      return toast.error("The final time is bigger than the show duration", {
        position: "top-right",
        autoClose: 5000,
      });
    }

    handleEditTrack(index, initialAmmount, finalAmmount)
    setTimeModal(false)
  }

  // INITIAL TIME 
  const increaseISec = () => {
    if (initialSec < 59) {
      setInitialSec(s => s + 1)
    } else {
      setInitialSec(0)
    }
  }

  const decreaseISec = () => {
    if (initialSec > 0) {
      setInitialSec(s => s - 1)
    } else {
      setInitialSec(59)
    }
  }

  const increaseIMin = () => {
    if (initialMin < 59) {
      setInitialMin(s => s + 1)
    } else {
      setInitialMin(0)
    }
  }

  const decreaseIMin = () => {
    if (initialMin > 0) {
      setInitialMin(s => s - 1)
    } else {
      setInitialMin(59)
    }
  }

  const increaseIHour = () => {
    if (initialHour < 23) {
      setInitialHour(s => s + 1)
    } else {
      setInitialHour(0)
    }
  }

  const decreaseIHour = () => {
    if (initialHour > 0) {
      setInitialHour(s => s - 1)
    } else {
      setInitialHour(23)
    }
  }

  // FINAL TIME 
  const increaseFSec = () => {
    if (finalSec < 59) {
      setFinalSec(s => s + 1)
    } else {
      setFinalSec(0)
    }
  }

  const decreaseFSec = () => {
    if (finalSec > 0) {
      setFinalSec(s => s - 1)
    } else {
      setFinalSec(59)
    }
  }

  const increaseFMin = () => {
    if (finalMin < 59) {
      setFinalMin(s => s + 1)
    } else {
      setFinalMin(0)
    }
  }

  const decreaseFMin = () => {
    if (finalMin > 0) {
      setFinalMin(s => s - 1)
    } else {
      setFinalMin(59)
    }
  }

  const increaseFHour = () => {
    if (finalHour < 23) {
      setFinalHour(s => s + 1)
    } else {
      setFinalHour(0)
    }
  }

  const decreaseFHour = () => {
    if (finalHour > 0) {
      setFinalHour(s => s - 1)
    } else {
      setFinalHour(23)
    }
  }

  return <ModalOverlay isOpen={timeModal}>
    <Box shadow="0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)" bgColor="#424242" width="380px" height="auto" justify="flex-start" padding="30px 40px 20px">
      <MenuTitle>Add a Track</MenuTitle>
      <Subtitle margin="30px auto 0 0">Start Time:</Subtitle>
      <Flex margin="0 auto 0 0" padding="0" flexDirection="row">

        <Flex margin="0 22px 0 0" padding="0">
          <TimeButton onClick={increaseIHour}>+</TimeButton>
          <TimeText>{timeToText(initialHour)}</TimeText>
          <TimeButton onClick={decreaseIHour}>-</TimeButton>
        </Flex>

        <Flex margin="0 22px 0 0" padding="0">
          <TimeButton onClick={increaseIMin}>+</TimeButton>
          <TimeText>{timeToText(initialMin)}</TimeText>
          <TimeButton onClick={decreaseIMin}>-</TimeButton>
        </Flex>

        <Flex margin="0 22px 0 0" padding="0">
          <TimeButton onClick={increaseISec}>+</TimeButton>
          <TimeText>{timeToText(initialSec)}</TimeText>
          <TimeButton onClick={decreaseISec}>-</TimeButton>
        </Flex>

      </Flex>
      <Subtitle margin="30px auto 0 0">End Time:</Subtitle>
      <Flex margin="0 auto 0 0" padding="0" flexDirection="row">

        <Flex margin="0 22px 0 0" padding="0">
          <TimeButton onClick={increaseFHour}>+</TimeButton>
          <TimeText>{timeToText(finalHour)}</TimeText>
          <TimeButton onClick={decreaseFHour}>-</TimeButton>
        </Flex>

        <Flex margin="0 22px 0 0" padding="0">
          <TimeButton onClick={increaseFMin}>+</TimeButton>
          <TimeText>{timeToText(finalMin)}</TimeText>
          <TimeButton onClick={decreaseFMin}>-</TimeButton>
        </Flex>

        <Flex margin="0 22px 0 0" padding="0">
          <TimeButton onClick={increaseFSec}>+</TimeButton>
          <TimeText>{timeToText(finalSec)}</TimeText>
          <TimeButton onClick={decreaseFSec}>-</TimeButton>
        </Flex>

      </Flex>

      <Flex flexDirection="row" margin="auto 0 0">
        <Button onClick={handleFinish} disabled={false} bgColor="#22EE22" width="140px" margin="0 20px 0 0">
          Change
        </Button>

        <Button onClick={handleCloseModal} bgColor="#EE2222" color="#202020" width="140px" margin="0">
          Cancel
        </Button>
      </Flex>
    </Box>

    <ToastContainer />
  </ModalOverlay>

}

export default TimeModal;