import React, { useState, useEffect } from 'react';

// COMPONENTS
import All from './All'
import Monitor from './Monitor'

// STYLED
import { BGContainer, Box, Flex } from '../../UI'
import { Container, SelectBtn } from './styles'


function Controls() {

  // MOCK
  const [midias, setMidias] = useState([
    {
      id: "vdjg45jnh7hfr5n96g",
      name: "The expetacular video that shows a cool thing",
      duration: 148,
      type: 'video'
    },
    {
      id: "vdjg46jnh7hfr5n96g",
      name: "Lorem Ipsum ad dolorem it mun ad senso strictus",
      duration: 345,
      type: 'video'
    },
    {
      id: "vdjg46jnh7hfr5n97g",
      name: "A cool picture",
      duration: 0,
      type: 'image'
    },
    {
      id: "vdjg46jnh7hfr5n98g",
      name: "My RTMP",
      duration: 0,
      type: 'rtmp'
    }
  ])

  const [monitors, setMonitors] = useState([
    {
      id: "bhj56402ndf0g45n96g",
      name: "M1",
      tracks: []
    },
    {
      id: "bhj86402ndf0g45n96g",
      name: "M2",
      tracks: []
    },
    {
      id: "bhj76402ndf0g45n96g",
      name: "M3",
      tracks: []
    },
    {
      id: "bhj66402ndf0g45n96g",
      name: "M4",
      tracks: []
    }
  ])

  const [selectedMenu, setSelectedMenu] = useState('All')
  const [selectedMonitor, setSelectedMonitor] = useState()

  const [flowDuration, setFlowDuration] = useState(3600)

  // RENDERS
  const render = () => {
    switch (selectedMenu) {
      case 'All':
        return <All setFlowDuration={setFlowDuration} monitors={monitors} flowDuration={flowDuration} />

      default:
        return <Monitor selectedMonitor={selectedMonitor} midias={midias} tracks={selectedMonitor?.tracks} selectedMenu={selectedMenu} monitors={monitors} setMonitors={setMonitors} flowDuration={flowDuration} />;
    }
  }

  useEffect(() => {
    setSelectedMonitor(monitors.filter(m => m.name === selectedMenu)[0])
  }, [selectedMenu, monitors])

  return <BGContainer>
    <Container>
      <Box bgColor="#303030" width="80%" height="80%" justify="flex-start">
        <Flex minHeight="94px" margin="0 auto 40px" flexDirection="row">

          <SelectBtn selected={selectedMenu === 'All'} onClick={() => setSelectedMenu('All')}>
            All
          </SelectBtn>

          {monitors.map(monitor => {
            return <SelectBtn selected={selectedMenu === monitor.name} onClick={() => setSelectedMenu(monitor.name)}>
              {monitor.name}
            </SelectBtn>
          })}

        </Flex>

        {render()}
      </Box>
    </Container>
  </BGContainer>;
}

export default Controls;