import '../home/home.scss'
export default function Login() {
  return (
    <div className="App">
      <header className="App-header">
        <button className='login'>
          <a href={`https://livecgi.auth.us-east-1.amazoncognito.com/login?client_id=${process.env.REACT_APP_CLIENT_ID}&response_type=code&scope=email+openid&redirect_uri=${process.env.REACT_APP_API_HOST}`}>LOGIN</a>
        </button>
      </header>
    </div>
  );
}