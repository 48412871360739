import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

// STYLES
import { Typography, CardActionArea, CardActions, CardContent, Button } from '@material-ui/core'
import { CardContainer, CardImg } from './styles'

function ProductCard({ product }) {

  const { user } = useSelector(redux => redux.authState)
  const history = useHistory()

  const getMenu = () => {
    if (user?.applications?.includes(product._id)) {
      return <Button onClick={handleClick} size="small" color="primary">
        Start
      </Button>
    }

    return <>
      <Button size="small" color="primary">
        Share
      </Button>
      <Button onClick={handleClick} size="small" color="primary">
        Learn More
      </Button>
    </>
  }

  const handleClick = () => {
    if (user?.applications?.includes(product._id)) {
      return history.push(`/${product.nameAlt}`)
    }

    return history.push(`/lead/${product.nameAlt}`)
  }

  return <CardContainer>
    <CardActionArea>
      <CardImg
        image={product.image}
        title={product.alt || "LiveCGI Stream Application"}
      />
      <CardContent>
        <Typography gutterBottom variant="h6" component="h2">
          {product.name}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          {product.description}
        </Typography>
      </CardContent>
    </CardActionArea>

    <CardActions>
      {getMenu()}
    </CardActions>
  </CardContainer>;
}

export default ProductCard;