// TYPES
import { LOGIN_SUCCESSFULL, LOGOUT, CHANGE_STATUS, UPDATE_USER } from '../constants/actionTypes'

export const logout = () => {
  return { type: LOGOUT }
}

export const authenticate = (user) => {
  return { type: LOGIN_SUCCESSFULL, payload: { user } }
}

export const changeStatus = (params) => {
  return { type: CHANGE_STATUS, payload: params }
}

export const updateUser = (params) => {
  return { type: UPDATE_USER, payload: params }
}