import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

// API
import { login } from '../../connections/auth'

// TOASTFY
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// IMAGES
import loader from '../../assets/loader.svg'

// STYLES
import { Container, Input, Loader } from './styles';
import { Box, Button, Typography } from '@material-ui/core';

// ACTIONS
import { authenticate } from '../../actions/auth';


function Portal() {

  const dispatch = useDispatch()
  const history = useHistory()

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const handleLogin = async () => {
    if (isLoading) return

    setIsLoading(true)

    try {
      let response = await login({ email_address: email, password });

      let userJWT = {
        accessJWT: response.accessToken.jwtToken,
        idJWT: response.idToken.jwtToken,
        refreshJWT: response.refreshToken.jwtToken
      }

      let user = {
        _id: response.databaseUser.id,
        name: response.databaseUser.first_name + " " + response.databaseUser.last_name,
        email: response.databaseUser.email_address,
        portrait: false,
        applications: ["3f894h398hf89345h"],
        session: userJWT,
        groups: [
          { id: "dfh340f38", name: "Test Group 1" },
          { id: "4g545g45f", name: "Test Group Large Name" }
        ],
        defaultGroup: "dfh340f38"
      }

      dispatch(authenticate(user))

      history.push("/dashboard")

    } catch (e) {
      console.log(e)
      toast.error(e.response.data.message);
    }

    setIsLoading(false)
  }

  const getButtonContent = () => {
    return isLoading ? <Loader src={loader} /> : 'Login'
  }

  return <Container>
    <Typography variant="h3">Welcome to Live CGI</Typography>

    <Input value={email} onChange={e => setEmail(e.target.value)} label="Email" variant="outlined" margin="100px 0 0" />

    <Input value={password} onChange={e => setPassword(e.target.value)} label="Password" variant="outlined" margin="28px 0 36px" type="password" />

    <Box width="300px" display="flex" justifyContent="space-between">
      <Box minWidth="80px" margin="0 18px 0 0">
        <Button onClick={handleLogin} size="big" variant="contained" color="primary" fullWidth>{getButtonContent()}</Button>
      </Box>
      <Button onClick={() => history.push("/signup")} size="big" variant="outlined" color="primary" fullWidth>Create an Account</Button>
    </Box>

    <ToastContainer />
  </Container>;
}

export default Portal;