import React, { useEffect, useState } from 'react';

// STYLED
import { Box, Flex, MenuTitle, Button, ModalOverlay } from '../../UI'
import { TimeButton, TimeText } from './styles'


function DurationModal({ durationModal, setDurationModal, setFlowDuration, flowDuration }) {

  const [initialSec, setInitialSec] = useState(0)
  const [initialMin, setInitialMin] = useState(0)
  const [initialHour, setInitialHour] = useState(1)

  useEffect(() => {
    getInitialTime()
  }, [])

  // UI
  const timeToText = (time) => {
    return time < 10 ? '0' + time : '' + time
  }

  // HANDLERS
  const handleCloseModal = () => {
    setDurationModal(false)
  }

  const handleFinish = () => {
    let initialAmmount = (initialHour * 3600) + (initialMin * 60) + initialSec
    setFlowDuration(initialAmmount)
    setDurationModal(false)
  }

  // DATA
  const getInitialTime = () => {
    let hour = Math.floor(flowDuration / 3600)
    let minutes = Math.floor((flowDuration % 3600) / 60)
    let seconds = flowDuration % 60

    setInitialHour(hour)
    setInitialMin(minutes)
    setInitialSec(seconds)
  }

  // INITIAL TIME 
  const increaseISec = () => {
    if (initialSec < 59) {
      setInitialSec(s => s + 1)
    } else {
      setInitialSec(0)
    }
  }

  const decreaseISec = () => {
    if (initialSec > 0) {
      setInitialSec(s => s - 1)
    } else {
      setInitialSec(59)
    }
  }

  const increaseIMin = () => {
    if (initialMin < 59) {
      setInitialMin(s => s + 1)
    } else {
      setInitialMin(0)
    }
  }

  const decreaseIMin = () => {
    if (initialMin > 0) {
      setInitialMin(s => s - 1)
    } else {
      setInitialMin(59)
    }
  }

  const increaseIHour = () => {
    if (initialHour < 23) {
      setInitialHour(s => s + 1)
    } else {
      setInitialHour(0)
    }
  }

  const decreaseIHour = () => {
    if (initialHour > 0) {
      setInitialHour(s => s - 1)
    } else {
      setInitialHour(23)
    }
  }

  return <ModalOverlay isOpen={durationModal}>
    <Box shadow="0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)" bgColor="#424242" width="380px" height="auto" justify="flex-start" padding="30px 40px 20px">
      <MenuTitle>Change Duration</MenuTitle>
      <Flex margin="8px auto" padding="0" flexDirection="row">

        <Flex align="center" margin="0 11px" padding="0">
          <TimeButton onClick={increaseIHour}>+</TimeButton>
          <TimeText>{timeToText(initialHour)}</TimeText>
          <TimeButton onClick={decreaseIHour}>-</TimeButton>
        </Flex>

        <Flex margin="0 11px" padding="0">
          <TimeButton onClick={increaseIMin}>+</TimeButton>
          <TimeText>{timeToText(initialMin)}</TimeText>
          <TimeButton onClick={decreaseIMin}>-</TimeButton>
        </Flex>

        <Flex margin="0 11px" padding="0">
          <TimeButton onClick={increaseISec}>+</TimeButton>
          <TimeText>{timeToText(initialSec)}</TimeText>
          <TimeButton onClick={decreaseISec}>-</TimeButton>
        </Flex>

      </Flex>

      <Flex flexDirection="row" margin="auto 0 0">
        <Button onClick={handleFinish} disabled={false} bgColor="#22EE22" width="140px" margin="0 20px 0 0">
          Change
        </Button>

        <Button onClick={handleCloseModal} bgColor="#EE2222" color="#202020" width="140px" margin="0">
          Cancel
        </Button>
      </Flex>
    </Box>
  </ModalOverlay>

}

export default DurationModal;