import { TextField, Select } from '@material-ui/core';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  max-height: 100vh;

  overflow: hidden;

  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: #F5F5F5;
`

export const Input = styled(TextField)`
  && {
    width: 300px;

    margin: ${p => p.margin};
  }
`

export const BroadcasrSelect = styled(Select)`
  && {
    height: 55px;
    width: 300px;
  }
`
